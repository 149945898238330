'use client';

import { ReactNode, useEffect, useCallback } from 'react';
import ReCaptchaProvider from '@/utils/ReCaptchaProvider';

interface ClientProvidersProps {
  children: ReactNode;
}

declare global {
  interface Window {
    grecaptcha: any;
    onRecaptchaLoad?: () => void;
    dataLayer: any[];
  }
}

const ClientProviders = ({ children }: ClientProvidersProps) => {
  const executeRecaptcha = useCallback(async () => {
    const siteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;
    
    if (!siteKey) {
      console.error('reCAPTCHA site key is not defined');
      return null;
    }

    try {
      const token = await window.grecaptcha.execute(siteKey, {
        action: 'lead_magnet_form'
      });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'recaptchaLoaded',
        recaptchaToken: token,
      });

      return token;
    } catch (error) {
      console.error('Error executing reCAPTCHA:', error);
      return null;
    }
  }, []);

  useEffect(() => {
    const siteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;

    if (!siteKey) {
      console.error('reCAPTCHA site key is not defined');
      return;
    }

    // Éviter les chargements multiples
    if (document.querySelector('script[src*="recaptcha"]')) {
      return;
    }

    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
    script.async = true;
    script.defer = true;

    // Définir le callback avant de charger le script
    window.onRecaptchaLoad = () => {
      if (window.grecaptcha) {
        window.grecaptcha.ready(() => {
          executeRecaptcha();
        });
      }
    };

    script.onload = () => window.onRecaptchaLoad?.();

    document.head.appendChild(script);

    return () => {
      // Nettoyage
      const scriptElement = document.querySelector('script[src*="recaptcha"]');
      if (scriptElement) {
        document.head.removeChild(scriptElement);
      }
      delete window.onRecaptchaLoad;
    };
  }, [executeRecaptcha]);

  return (
    <ReCaptchaProvider>
      {children}
    </ReCaptchaProvider>
  );
};

export default ClientProviders;
